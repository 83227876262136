<template>

  <div class="mainContain">
    <div class="box">
      <div class="list">
        <div :class="[!userInfo.gptsUserPhone ? 'error' : '', 'item']">
          <div class="icon">
            <img loading="lazy" src="@/assets/secure1.png" alt="" />
          </div>
          <div class="content">
            <div class="title">Mobile phone binding</div>
            <p class="text2" v-if="userInfo.gptsUserPhone">
              Mobile phone number <span class="red">{{ userInfo.gptsUserPhone }} </span
            > has been bound, binding the mobile phone number can quickly retrieve the password and verify the identity, to ensure the security of your account.
            </p>
            <p class="text1" v-else>You are not currently bound to your phone.</p>
          </div>

          <div class="button" @click="toPhone()">
            <span v-if="userInfo.gptsUserPhone">Modification</span>
            <span v-else>Binding</span>
          </div>
        </div>
        <div :class="[!userInfo.gptsUserEmail ? 'error' : '', 'item']">
          <div class="icon">
            <img loading="lazy" src="@/assets/secure2.png" alt="" />
          </div>
          <div class="content">
            <div class="title">Mailbox binding</div>
            <p class="text2" v-if="userInfo.gptsUserEmail">
              The mailbox <span class="red">{{ userInfo.gptsUserEmail }}</span
            > has been bound, binding mailbox can quickly retrieve password and verify identity, to ensure the security of your account.
            </p>
            <p class="text1" v-else>You have not yet bound your mailbox.</p>
          </div>

          <div class="button" @click="toEmail()">
            <span v-if="userInfo.gptsUserEmail">Modification</span>
            <span v-else>Binding</span>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img loading="lazy" src="@/assets/secure3.png" alt="" />
          </div>
          <div class="content">
            <div class="title">Login password</div>
            <p class="text1">
              You are advised to change your password periodically because a secure password enhances account security.
            </p>
          </div>

          <div class="button" @click="toPassword()">
            <span>Modification</span>
          </div>
        </div>
        <div class="item">
          <div class="icon">
            <img loading="lazy" src="@/assets/secure4.png" alt="" />
          </div>
          <div class="content">
            <div class="title">Wechat unbind</div>
            <p class="text2" v-if="isBind">You have been bound to wechat, click unbind.</p>
            <p class="text1" v-else>
              You do not have wechat binding, please go to the login page for binding.
            </p>
          </div>

          <div class="button" @click="toWeixin()" v-if="isBind">
            <span>Unbind</span>
          </div>
        </div>
      </div>
      <el-dialog
          title="Warm reminder"
          :visible.sync="dialogVisible"
          width="480px"
          :append-to-body="true"
          :before-close="handleClose"
          top="40vh"

      >
        <span>Do you want to confirm unbinding? After unbinding, you cannot log in through wechat.</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleClose"> Cancel </el-button>
          <el-button type="primary" @click="handTo"> Confirm </el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import { unbindAccount, social } from "@/api/user";
import { shareUrl } from "@/utils/vxshare.js";
export default {
  name: "Secure",
  components: {  },
  data() {
    return {dialogComment:false,
      dialogProduct:false,
      dialogNet:false,
      baseURL: this.$utils.baseURL,
      userInfo: localStorage.getItem("userInfo"),
      dialogVisible: false,
      languageSelect: "",
      isBind: false,
    };
  },
  computed: {},
  watch: {
    "$store.state.user"(newValue) {
      this.userInfo = newValue;
    },
  },
  created() {

    // console.log(this.userInfo)
    this.userInfo=JSON.parse(this.userInfo)
    // console.log(this.userInfo.email)
    this.social();
    let url = window.location.host;
    // let url = 'https://oilgasinfoai.com/?language=zh'
    setTimeout(() => {
      //encodeURIComponent 将你的URL进行转译一下，因为微信分享出去后会带有多余用不到的参数
      let shareData = {
        url: url,
        title: "Oil & Gas General",

      };
      shareUrl(shareData);
    }, 1000);
  },
  mounted() {},
  methods: {
    toPhone() {
      if (this.userInfo.phonenumber) {
        this.$router.push({ path: "/changePhone", query: { type: 1 } });
      } else {
        this.$router.push("/changePhone");
      }
    },
    toEmail() {
      if (this.userInfo.phonenumber) {
        this.$router.push({ path: "/changeEmail", query: { type: 1 } });
      } else {
        this.$router.push("/changeEmail");
      }
    },
    toPassword() {
      this.$router.push("/changePassword");
    },
    toWeixin() {
      this.dialogVisible = true;
    },
    social() {
      let data = {
        language: "zh",
        access_token: localStorage.getItem("Zyaccess_token"),
      };
      social(data).then((res) => {
        console.log(res);
        if (res.data.isBind) {
          this.isBind = res.data.isBind;
          this.socialUid = res.data.socialUid;
        } else {
          this.isBind = false;
          // if (this.languageSelect == "zh") {
          //   this.$message.error("您暂未进行微信绑定 请前往登录页面进行绑定");
          // } else {
          //   this.$message.error(
          //     "You have not yet bound to WeChat. Please go to the login page to bind"
          //   );
          // }
        }
      });
    },
    handTo() {
      let data = {
        access_token: localStorage.getItem("Zyaccess_token"),
        socialUid: this.socialUid,
      };
      unbindAccount(data).then(() => {
        this.dialogVisible = false;
        this.socialUid = null;
        if (this.languageSelect == "zh") {
          this.$message({
            message: "Unbind successfully!", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              location.reload();
            },
          });
        } else {
          this.$message({
            message: "Unbind successful!", //提示的信息
            type: "success", //类型是成功
            duration: 1200, //显示时间, 毫秒。设为 0 则不会自动关闭，建议1200
            onClose: () => {
              //跳转页面或执行方法
              location.reload();
            },
          });
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>


<style type="text/scss" lang="scss" scoped>
.mainContain{
  width: 65vw;
  border-radius: 9px;
  margin:0 1vw;
  background-color: white;
  height: 75vh;
  padding: 0 2vw 3vh 0;
  position: relative;
  background-image:url("../../assets/oilba.png");
  background-size:cover;
  background-position:center;
  background-repeat:no-repeat;
  background-color: transparent;
  .box {
    .ViewTitle {
      height: 67px;
      line-height: 67px;
      border-bottom: 1px solid #c0bebe;
      font-size: 20px;
      color: #333333;
      font-weight: 400;
      padding: 0 30px;
    }

    .list {
      padding: 0 3vw;
      .item {
        padding: 5vh 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width:4vh;
          height: 4vh;
          margin-right: 2vw;
        }
        .content {
          flex: 1;
          width: 0;
          .title {
            font-size: 1vw;
            color: #222222;
            margin-bottom: 1vh;
          }
          p {
            font-size: 0.9vw;
            color: #333333;
          }
        }
        .button {
          width: 7vw;
          height: 6vh;
          line-height: 6vh;
          align-items: center;
          justify-content: center;
          text-align: center;
          border: 1px solid var(--main-color) ;
          color: #333333;
          border-radius: 9px;
          font-size: 0.9vw;
          cursor: pointer;
        }
      }
      .item:not(:last-child) {
        border-bottom: 1px solid #ebebeb;
      }
    }
  }
  ::v-deep {
    .el-dialog__header {
      border-bottom: 1px solid #ebebeb;
      .el-dialog__title {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .el-button {
      width: 100px;
      height: 28px;
      line-height: 28px;
      border-radius: 5px;
      text-align: center;
      cursor: pointer;
      border: 1px solid var(--main-color);
      color: var(--main-color);
      padding: 0;
    }
    .el-button--primary {
      border: 1px solid var(--main-color);
      background: var(--main-color);
      color: #fff;
    }
    .el-button:focus,
    .el-button:hover {
      color: var(--main-color);
      border-color: var(--main-color);
      background-color: #fff;
    }
    .el-button--primary:focus,
    .el-button--primary:hover {
      background: var(--main-color);
      border-color: var(--main-color);
      color: #fff;
    }
    .el-dialog__headerbtn:focus .el-dialog__close,
    .el-dialog__headerbtn:hover .el-dialog__close {
      color: var(--main-color);
    }
  }
  .red {
    color: var(--main-color) !important;
  }
}
::v-deep .el-dialog__wrapper
{
  z-index: 999999;
}
</style>
